import algoliasearch from "algoliasearch";

export const useAlgoliaSearch = () => {
	const {
		public: { algolia }
	} = useRuntimeConfig();
	const { sessionContext } = useShopwareSession();

	const client = algoliasearch(
		(algolia as Record<string, string>).applicationId,
		(algolia as Record<string, string>).apiSearchKey,
		{
			headers: {
				"X-Algolia-UserToken": sessionContext.value?.token || "unknown"
			}
		}
	);

	return {
		client
	};
};
